:root {
	--gutter: 5rem;
	--total-columns: 12;
	--aside-columns: 5;
	--content-columns: 4;
	--asideWidth: calc(
		var(--grid-column-width) * var(--aside-columns) + var(--grid-gap) * (var(--aside-columns) - 1) +
			var(--gutter)
	);
	--contentWidth: calc(
		var(--grid-column-width) * var(--content-columns) + var(--grid-gap) *
			(var(--content-columns) - 1)
	);
	--content-margin-left: calc(var(--asideWidth) + var(--grid-column-width) + var(--grid-gap));
	--wrapper-padding: var(--gutter);
	--aside-width: calc(
		calc(var(--column-width) * var(--aside-columns)) +
			(var(--grid-gap) * calc(var(--aside-columns) - 1))
	);
	--content-width: calc(
		(var(--column-width) * var(--content-columns)) +
			(var(--grid-gap) * calc(var(--content-columns)))
	);
	--margin-left: var(--aside-width);
}

@media (max-width: 96rem) {
	:root {
		--aside-columns: 4;
		--content-columns: 5;
	}
}

@media (max-width: 80rem) {
	:root {
		--aside-columns: 3;
		--content-columns: 4;
		--content-width: calc(
			(var(--column-width) * var(--content-columns)) +
				(var(--grid-gap) * calc(var(--content-columns)))
		);
	}
}

@media (max-width: 64rem) {
	:root {
		--aside-columns: 2;
		--content-columns: 2;
		--margin-left: calc(var(--aside-width) + var(--grid-gap));
		--wrapper-padding: 0;
		--asideWidth: calc(
			var(--grid-column-width) * var(--content-columns) + var(--grid-gap) *
				(var(--content-columns) - 1) + var(--gutter)
		);
	}
}

.wrapper {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	background: transparent;
	overflow-y: scroll;
	.content {
		@media (min-width: 56rem) {
			display: flex;
			justify-content: center;
		}
		flex: 1;

		padding: 5rem var(--gutter) 1.625rem var(--grid-column-width);

		overflow-x: hidden;
	}

	.sipgateLogo {
		display: block;
		position: absolute;
		height: 2.5rem;
		z-index: 1001;
		top: 0;
		left: 0;
		margin: 1rem;
	}
	.sipgateLogo.dark {
		filter: invert(1);
	}
}

@media (max-width: 80rem) {
	.wrapper {
		.content {
			padding-left: 7.375rem;
		}
	}
}

@media (max-width: 64rem) and (orientation: portrait) {
	.wrapper {
		height: auto;
		flex-direction: column-reverse;

		.content {
			padding-top: 7rem;
			padding-right: var(--gutter);
			padding-left: var(--gutter);

			width: 100%;
			margin-left: 0;
			margin-bottom: 0;
		}
	}
}

/** iPhone SE landscape **/
@media (max-width: 56rem) and (orientation: landscape) {
	.wrapper {
		height: auto;
		flex-direction: column-reverse;

		& > .sipgateLogo {
			display: block;
			left: unset;
			padding-left: var(--gutter);
		}

		.content {
			padding-top: calc((var(--grid-gap) * 2) + 5rem);
			padding-right: var(--gutter);
			padding-left: var(--gutter);

			width: 100%;
			margin-left: 0;
			margin-bottom: 0;
		}
	}
}

@media (max-width: 48rem) {
	.wrapper {
		.content {
			padding: 0;
			display: flex;
			flex-direction: column;
		}
	}
}
